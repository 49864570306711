.tests-page{
  .test-list{
    list-style-type: none;
    padding: 0;

    li{
      margin: 0 0 13px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0 0 13px 0;
      border-bottom: 1px solid rgba($black, .06);

      &:last-child{
        border-bottom: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.tests-page, .details-page{
  .package-list{
    list-style-type: none;
    padding: 0;
    position: sticky;
    top: 20px;
    
    li{
      margin: 10px 0;

      &:first-child{
        margin-top: 0;
      }

      a{
        width: 100%;
        display: block;
        font-size: 15px;
        font-weight: 500;
        color: $black;
        padding: 9px 17px;
        transition: $transitionClassic;
        border-radius: 8px;
        user-select: none;

        &:hover, &.active{
          background-color: rgba($altParagraph, .1);
        }
      }
    }
  }
}

.test-page{
  .card{
    .card-content{
      padding: 30px;
    }
  }

  .test-nav-tabs{
    width: 100%;
    .ant-tabs-nav{
      margin-bottom: 0;
    }
  }

  .test-info-item{
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .test-content{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .test-img-placeholder{
      width: 100%;
      height: 400px;
      background: $lightgrey;
    }
  }

  .test-answers{
    &.checkbox-answers{
      .ant-checkbox-group-item{
        display: block !important;
        margin: 15px 0;
      }

      .checkbox-answer{
        display: flex;
        margin-left: 0;
        margin-bottom: 10px;
        line-height: 150%;
      }
    }
  
    &.radio-answers{
      .radio-answer{
        color: $black;
        display: block;
        margin: 0 0 7px 0;
  
        span{
          white-space: normal !important;
          line-height: 150%;
        }
  
        &.radio-img-correct{
          position: relative;
  
          &:before{
            content: "Correct";
            position: absolute;
            bottom: 15px;
            left: 15px;
            display: block;
            font-size: 13px;
            line-height: 13px;
            color: #52c41a;
            background: #f6ffed;
            border: 1px solid #b7eb8f;
            padding:  3px 7px;
            border-radius: 3px;
            cursor: default;
            z-index: 11;
            box-shadow: 0px 4px 9px -5px rgba(2, 63, 8, 0.75);
          }
  
        }
  
      }
  
      &.radio-img{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        .radio-answer{
          position: relative;
          box-sizing: border-box;
          width: calc(50% - 10px);
          border-radius: 6px;
          border: 1px solid rgba($black, .1);
          margin: 5px;
          overflow: hidden;

          &:hover{
            .ant-radio{
              opacity: 1;
            }
          }

          &:after{
            display: none;
          }
  
          &:nth-child(odd){
            margin-left: 0;
          }
  
          &:nth-child(even){
            margin-right: 0;
          }
  
          .ant-radio{
            position: absolute;
            top: 10px;
            left: 10px;
            opacity: .5;
            transition: $transitionClassic;
          }
  
          span{
            &:not([class*='ant-radio']) {
              display: block;
              width: 100%;
              padding: 0;
              margin: 0;
            }
          }
  
          img{
            display: block;
            width: 100%;
          }
        }
      }
    }
  
    .answer-problem{
      display: block;
      font-size: 17px;
      font-weight: 500;
      line-height: 25px;
      color: $purple;
      margin-bottom: 25px;
    }
  
    .select-answers{
  
      .select-answer{
        display: block;
        width: 100%;
  
        .answer-content{
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $black;
          margin: 10px 0;
          display: block;
        }
  
        .select-input{
          width: 50%;
          min-width: 200px;
          max-width: 400px;
        }
      }
  
    }
  
    .input-divider{
      margin: 15px 0;
    }
  }
}

.results-page{
  .status{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 80px 0 40px 0;

    .status-icon{
      display: block;
      width: 55px;
      height: 55px;
      margin-right: 20px;
    }
  }

  .gauge-chart{
    margin: 48px auto;
    user-select: none;

    svg{
      overflow: visible;

      text{
        fill: red;
      }
    }
  }

  .record-card{
    transition: $transitionClassic;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    @apply p-3 lg:p-4 md:flex-nowrap flex-wrap flex-col md:flex-row;
    user-select: none;

    .question-chip{
      min-width: 30px;
    }

    .question-problem{
      display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
  }
}