// @tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

// Colors
$white: #FFFFFF;
$black: #000000;
$purple: #4706D1;
$red: #FA1366;
$lightgrey: #F3F4F5;
$altParagraph: #5a6479;

// Breakpoints
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

$maxContainerWidth: 1180px;

// Globals
$transitionClassic: all .17s ease-in-out;

// Img
// $loginBGR: url("/img/login-bgr.png");

html, body{
  scroll-behavior: smooth;
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialised;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  ::selection{
    color: $white;
    background: $black;
  }
}

.bgr-grey{
  background-color: $lightgrey;
}

.container{
  padding-left: 20px;
  padding-right: 20px;
  &.max-content{
    max-width: $maxContainerWidth;
    margin: 0 auto;
  }
}

.loading-global{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $lightgrey;
  color: darken($lightgrey, 20);

  &.compact {
    width: 100%;
    height: 100%;
    min-height: 300px;
    background: none;
  }
}

.ant-layout-footer{
  background: none;
}

.ant-popover-inner{
  @apply shadow-lg;
}

.company-logo-test{
  max-width: 200px;
  max-height: 35px;
  display: block;
}

.bundle-widget{
  .lit{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FA1366;
    color: $white;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    width: fit-content;
    padding: 6px 11px;
    border-radius: 100px;
    box-shadow: 0px 3px 10px rgba(250, 19, 102, 0.4);

    img{
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .title{
    // font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    color: $white;
    letter-spacing: -1%;
    display: block;
  }

  .feature-list{
    list-style-type: none;

    li{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // flex-wrap: wrap;
      // font-size: 15px;
      font-weight: 500;
      color: $white;
      margin: 10px 0;

      img{
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

@import './components/typography';
@import './components/layout';
@import './components/breadcrumbs';
@import './components/login';
@import './components/checkout';
@import './components/dashboard';
@import './components/tests';
@import './components/cards';
@import './components/quiz';