.dashboard-page{

  .newsfeed{
    position: relative;

    .ant-avatar{
      @apply bg-slate-100;
    }

    .ant-comment-inner{
      padding: 0;
    }

    &:after{
      z-index: 10;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 40px;
      width: 100%;
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#ffffff',GradientType=0 );
    }
  }

  .newsfeed-comment{
    height: auto;
    max-height: 350px;
    overflow: scroll;
    padding-bottom: 40px;

    h1,h2,h3,h4,h5,h6{
      font-size: 17px;
      font-weight: 600;
      color: $black;
    }

    p{
      color: $black;
      @apply text-sm;
    }

    ul{
      margin: 20px 0;
    }
  }
}

.dashboard-page, .details-page{
  .test-list, .history-list{
    width: 100%;
    list-style-type: none;
    padding: 0;

    li{
      margin: 0 0 15px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 15px 0;
      border-bottom: 1px solid rgba($black, .06);

      &:last-child{
        border-bottom: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  .company-logo{
    display: block;
    width: 100%;
    max-width: 200px;
    max-height: 35px;
    object-fit: contain;
    object-position: left;
  }
}