.checkout-layout{
  .login-checkbox{
    display: block;
    width: 100%;
    margin: 8px 0 !important;

    .ant-form-item-control-input{
      min-height: unset;
    }
  }
}