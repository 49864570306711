.site-layout{
  min-height: 100vh;

  .header{
    background: $black;
    height: auto;
    min-height: 0;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    .nav-container{
      display: flex;
      align-items: center;
      width: 100%;

      .logo{
        float: left;
        height: 35px;
        margin-right: 20px;

        img{
          object-fit: contain;
          display: block;
          height: 100%;
        }
      }
    }

    .topnav{
      background: $black;
      width: 100%;
    }
  }
}

.checkout-layout{
  background: $lightgrey;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .ant-form-item{
    margin-bottom: 15px;
  }

  .summary-card{
    width: 100%;
    max-width: 450px;

    @media only screen and (max-width: $md) {
      max-width: unset;
      border-radius: 0;
      box-shadow: none;
      padding: 10px;
    }

    .summary-list{
      list-style-type: none;
      padding: 0;

      li{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        margin: 10px 0;
      }
    }
  }

  .login-content{
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;

    h1,h2,h3,h4,h5,h6{
      color: $black;
    }

    h1{
      font-size: 2.000em;
      margin: 1.000em 0;
    }

    .logo-signin{
      img{
        height: 40px;
        display: block;
      }
    }

    .login-form{
      max-width: 400px;

      @media only screen and (max-width: $md) {
        max-width: unset;
        width: 100%;
      }

      .login-checkbox{
        display: block;
        width: 100%;
        margin: 5px 0;

        .ant-form-item-control-input{
          min-height: unset;
        }
      }
    }
  }

  .signin-layout{
    background: $white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: $md) {
      padding-top: 0;
      .login-form{
        max-width: unset;
        width: 100%;
      }
    }

    .login-form{
      width: 100%;
      max-width: 400px;
      margin: 20px 0;

      .ant-form-item{
        margin-bottom: 15px;
      }
    }
  }

  .order-status-info{
    width: 100%;
    max-width: 500px;
  }

  .faq-btn-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    button{
      margin: 10px 10px 10px 0;
    }
  }
}

.offline-msgbox{
  max-width: 400px;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
  
  .msg{
    box-shadow: 0px 5px 10px -5px rgba(36, 8, 13, .2);
  }
}