.card{
  border-radius: 20px;
  background-color: $white;

  .card-content{
    padding: 40px;

    @media (max-width: 640px) {
      padding: 30px;
    }
  }
  
  .card-content-test{
    padding: 25px;
  }

  &--modal{
    .ant-modal-content{
      border-radius: 12px;
    }
  }

}