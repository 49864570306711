.quiz-modal{
  .ant-modal-content{
    overflow: hidden;
    border-radius: 10px;

    .ant-modal-body{
      padding: 0;
    }
  }

  .quiz-content-overlay{
    position: relative;

    .inform-chip{
      position: absolute;
      width: fit-content;
      height: fit-content;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      color: $white;
      font-size: 20px;
      font-weight: 500;
      z-index: 11;
      padding: 4px 12px;
      border-radius: 999px;
      text-transform: uppercase;
      letter-spacing: 1px;
      @apply bg-green-600;

      &.incorrect{
        @apply bg-red-500;
      }
    }
  }

  .quiz-content{
    display: flex ;
    flex-direction: column;
    align-items: center;

    .chat {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    
    .messages {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }
    
    .message {
      border-radius: 20px;
      padding: 8px 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      display: inline-block;
    }
    
    .yours {
      align-items: flex-start;
    }
    
    .yours .message {
      margin-right: 25%;
      background-color: #eee;
      position: relative;
    }
    
    .yours .message.last:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: -7px;
      height: 20px;
      width: 20px;
      background: #eee;
      border-bottom-right-radius: 15px;
    }
    .yours .message.last:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-right-radius: 10px;
    }
    
    .mine {
      align-items: flex-end;
    }
    
    .mine .message {
      color: white;
      margin-left: 25%;
      background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
      background-attachment: fixed;
      position: relative;
    }
    
    .mine .message.last:before {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      right: -8px;
      height: 20px;
      width: 20px;
      background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
      background-attachment: fixed;
      border-bottom-left-radius: 15px;
    }
    
    .mine .message.last:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-left-radius: 10px;
    }
    
  }
}